

input{
    border: 1px solid gray;
}
label{
    font-size: 14px;
    color: black;
    font-weight: bold;
}

span{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"]{
    border: 1px solid red;
}

input:invalid[focused="true"] ~ span{
    display: block;
    margin-bottom: 8px;
}